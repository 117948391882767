import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import style from 'scss/pages/chiSiamo.module.scss'
import Lines from 'components/lines'
import Image from 'components/image'

class ChiSiamo extends React.Component {
  render() {
    const { location, data } = this.props

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="Profile" />

        <div className={style.header}>
          <Lines />
          <div className={'container'}>
            <div className={'row'}>
              <div className={'col-md-5'}>
                <h2 className={'has-color-elements-dark'}>
                  404
                  <br />
                  Page not found
                </h2>
              </div>

              <div className={'offset-md-1 col-md-5 image'}></div>
            </div>

            <div className={style.header__payoff}>
              <hr />
              <h4>
                <span>your idea.</span> our amazing creation
              </h4>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ChiSiamo
